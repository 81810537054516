<template>
  <a-spin :spinning="loading">
    <div class="example-table">
      <div class="notice">
        <span>Steps: </span>
        <div>
          <p>1. Download CSV Template with payment details datas.</p>
          <p>2. Edit CSV and save.</p>
          <p>3. Upload CSV to bulk manage datas.</p>
        </div>
      </div>
      <div class="down-example">
        <artmisads-select-button @click="exportDownloadTemplate">
          <svg class="icon icon-daochu1" aria-hidden="true">
            <use xlink:href="#icon-daochu1"></use>
          </svg>
          Download Payment CSV
        </artmisads-select-button>
      </div>
      <div class="content">
        <div class="content-left">
          <div class="table">
            <artmisads-table
              :columns="columns"
              row-key="id"
              :data-source="exampleDataList"
              :pagination="false"
              :bordered="true"
            >
            <template #bodyCell="{ column, record, text }">
              <template v-if="record.id === 1">
                <span v-if="column.name === 'Paid'">{{text}}<span style="color: rgba(242, 85, 68, 1);">(Attention, this amount includes Description the receipt amounts that have been submitted and confirmed previously.)</span></span>
                <span v-else>{{text}}<span style="font-weight: 600;">(DO NOT Edit)</span></span>
              </template>
            </template>
            </artmisads-table>
          </div>
          <div class="file-name" v-if="fileName">
            <div>
              <svg class="icon icon-biaoge" aria-hidden="true">
                <use xlink:href="#icon-biaoge"></use>
              </svg>
              <span class="name">{{fileName}}</span>
              <svg class="icon close-cu" aria-hidden="true" @click="clearFile">
                <use xlink:href="#icon-close-cu"></use>
              </svg>
            </div>
          </div>
          <a-upload
            name="avatar"
            list-type="picture-card"
            :show-upload-list="false"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            :before-upload="beforeUpload"
            @change="handleChange"
            v-else
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <svg class="icon shangchuan2" aria-hidden="true">
              <use xlink:href="#icon-jiahao-cu"></use>
            </svg>
            Choose a file to update...
          </a-upload>
        </div>
        <div class="content-right">
          <div class="description">
            <span class="line"></span>
            <span class="text">Description</span>
          </div>
          <div class="required">
            <span class="line"></span>
            <span class="text">Required</span>
          </div>
          <div class="example">
            <span class="line"></span>
            <span class="text">Example</span>
          </div>
        </div>
      </div>
      <div class="next-btn">
        <artmisads-button type="primary" @click="toNext" v-if="dataList.length">
          Next
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiantou-you-cu"></use>
          </svg>
        </artmisads-button>
        <artmisads-button type="primary" v-else disabled>Next</artmisads-button>
      </div>
    </div>
  </a-spin>
</template>
<script setup>
  import moment from 'moment';
  import { message } from 'ant-design-vue';
  import { ref } from 'vue';

  import { financeApi } from "~/server/operator/finance";

  const fileName = ref('');
  const loading = ref(false);
  const props = defineProps(['setDataList', 'setShowExample', 'dataList', 'id', 'setMarketplace', 'setBillingDateTimestamp']);

  const clearFile = () => {
    fileName.value = '';
    props.setDataList([]);
  }

  const exampleDataList = [
    {
      id: 1,
      Username: 'ArtemisAds Account Full Name',
      AccountName: 'ArtemisAds Account',
      Brands: 'ArtemisAds Account Brands',
      InvoiceNo: 'Unique Invoice No. to each Seller based on monthly payment ',
      DBA: ' Company name filled by seller',
      Amount: 'Amount to pay by seller ',
      Status: 'Seller payment status ',
      Paid: 'Amount paid by seller, fill in by ArtemisAds '
    },
    {
      id: 2,
      Username: 'YES',
      AccountName: 'YES',
      Brands: 'YES',
      InvoiceNo: 'YES',
      DBA: 'NO',
      Amount: 'YES',
      Status: 'YES',
      Paid: 'YES'
    },
    {
      id: 3,
      Username: 'Don',
      AccountName: 'Don Jonny \n DonJonny@163.com',
      Brands: 'HOTO,SHioo,CHANDO',
      InvoiceNo: 'AA-12313',
      DBA: 'Chengdu XX Inc.Ltd',
      Amount: '$5000',
      Status: 'Not Paid',
      Paid: ''
    },
  ];
  const columns = [
    {
      title: 'ArtemisAds Account',
      dataIndex: 'AccountName',
      width: '11%'
    },
    {
      title: 'Brands',
      dataIndex: 'Brands',
      width: '10%'
    },
    {
      title: 'Invoice No.',
      dataIndex: 'InvoiceNo',
      width: '15%'
    },
    {
      title: 'DBA',
      dataIndex: 'DBA',
      width: '13%'
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      name: 'Amount',
      width: '11%'
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      name: 'Status',
      width: '10%'
    },
    {
      title: 'Paid',
      dataIndex: 'Paid',
      name: 'Paid',
      width: '27%'
    }
  ];

  const exportDownloadTemplate = () => {
    const finalParams = {
      qs: {
        id: props.id
      },
      fileName: `payment-template-${moment().format('YYYY-MM-DD')}.csv`,
      platform: 'operator'
    }
    financeApi.downloadSellerPaymentConfirmTemplateFile(finalParams);
  }

  const handleChange = async ({file}) => {
    const { type, originFileObj, percent, name } = file;
    let form = new FormData();
		form.append('file', originFileObj);
    if (percent === 100) {
      if (type !== 'text/csv') {
        return message.error('Please upload a csv file.');
      } else {
        loading.value = true;
        let res = await financeApi.updaloadSellerPaymentConfirmFile(form);
        const { updateRecords, marketplace, billingDateTimestamp } = res;
        if (updateRecords) {
          if (!updateRecords.length) {
            message.warning('No matching data found.');
          } else {
            message.success('Success');
            const dataList = updateRecords;
            props.setMarketplace(marketplace);
            props.setDataList(dataList);
            props.setBillingDateTimestamp(billingDateTimestamp);
            fileName.value = name;
          }
          
        }
        loading.value = false;
      }
    }
  }

  const toNext = () => {
    props.setShowExample(false);
  }

</script>

<style lang="less" scoped>
  .example-table {
    .notice {
      padding-right: 160px;
      display: flex;
      > span {
        letter-spacing: 0.4px;
        line-height: 20px;
        color: var(--dark-2);
        margin-right: 10px;
      }
      > div {
        > p {
          letter-spacing: 0.4px;
          line-height: 20px;
          color: var(--dark-2);
          padding-bottom: 10px;
        }
      }
      
    }
    
    .content {
      padding: 12px 0 24px ;
      display: flex;
      :deep(.ant-table-wrapper) {
        .ant-table-cell {
          padding: 8px;
          line-height: 20px;
          word-break: break-word;
          border-color:  var(--border-4) !important;
        }
        .ant-table-thead {
          .ant-table-cell {
          border-bottom-color:  var(--border-1) !important;
          }
        }
        .ant-table-container {
          border-color:  var(--border-4) !important;
        }
        table {
          border-top: none !important;
          border-color:  var(--border-4) !important;
        }
      }
    }
    .content-right {
      margin-left: 8px;
      margin-top: 40px;
      .description,
      .required,
      .example {
        display: flex;
        .line {
          width: 2px;
          height: 100%;
        }
        .text {
          font-weight: 500;
          line-height: 20px;
          padding-left: 8px;
          padding-right: 8px;
          height: 20px;
          align-self: center;
          border-radius: 4px;
          margin-left: 16px;
        }
      }
      .description {
        height: 112px;
        .line {
          background-color: var(--primary-bg);
        }
        .text {
          color: var(--primary-text);
          background-color: var(--table-cell-bg-3);
          border: 1px solid var(--primary-border-3);
        }
      }
      .required {
        height: 34px;
        margin-top: 4px;
        .line {
          background-color: var(--yellow-5);
        }
        .text {
          color: var(--yellow-4);
          background-color: var(--table-cell-bg-2);
          border: 1px solid var(--yellow-5);
        }
      }
      .example {
        height: 56px;
        margin-top: 4px;
        .line {
          background-color: var(--green-4);
        }
        .text {
          color: var(--green-1);
          background-color: var(--table-cell-bg-1);
          border: 1px solid var(--green-2);
        }
      }
    }
    .table {
      width: 1020px;
    }
    .down-example {
      margin-top: 12px;
      button {
        display: flex;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          align-self: center;
        }
      }
    }
    .content-left {
      .file-name {
        display: flex;
        width: 100%;
        height: 60px;
        cursor: pointer;
        background: var(--big-bg-1);
        border-radius: 8px;
        color: var(--dark-2);
        margin-top: 8px;
        &:hover {
          background: var( --bg-gray-2);
        }
        > div {
          display: flex;
          height: 28px;
          padding: 0 24px;
          border-radius: 8px;
          align-self: center;
          // &:hover {
          //   background-color: var(--bg-gray-4);
          // }
          .icon-biaoge {
            width: 24px;
            height: 24px;
            fill: var( --primary-text);
            align-self: center;
          }
          .name {
            color: var( --primary-text);
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.4px;
            line-height: 22px;
            margin-left: 8px;
            margin-right: 24px;
            align-self: center;
          }
          .close-cu {
            cursor: pointer;
            width: 24px;
            height: 24px;
            padding: 4px;
            align-self: center;
            fill: var(--dark-3);
            &:hover {
              fill: var(--dark-1);
            }
          }
        }
        
      }
      :deep(.ant-upload-wrapper){
        margin-top: 16px;
        .ant-upload.ant-upload-select {
          width: 100%;
          height: 60px;
          color: var(--dark-2);
          background: var(--big-bg-1);
          border: 1px dashed var(--border-1);
          border-radius: 8px;
          font-size: var(--font-size-h-l);
          font-weight: 500;
          &:hover {
            background: var( --bg-gray-2);
            border: 1px dashed var(--bg-gray-7);
          }
          svg {
            width: 24px;
            height: 24px;
            fill: var(--dark-2);
            margin-right: 8px;
          }
        }
      }
    }
    .next-btn {
      border-top: 1px solid var(--border-4);
      padding: 10px 0 0 0;
      display: flex;
      justify-content: right;
      // > button {
      //   border: 2px solid var(--primary);
      //   box-shadow: var(--section-box-shadow-2);
      //   background-color: var(--color-white) !important;
      //   color: var(--primary);
      //   svg {
      //     fill: var(--primary);
      //     margin-left: 8px;
      //   }
      // }
    }
  }
</style>