<!--
 * @Author: fang.diao
 * @Date: 2024-12-11 17:20:20
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-25 15:46:31
 * @FilePath: /web_artemisads/src/pages/seller/product/fileBar/index.vue
 * @Description: 
-->
<template>
  <div class="file-bar" ref="fileBarRef">
    <artmisads-button-special class="upload-btn" @click="() => setShowUpdateProductModal(true)">
      <svg class="icon shangchuan2" aria-hidden="true">
        <use xlink:href="#icon-shangchuan2"></use>
      </svg>
      <span class="btn-text">Bulk Management Via CSV</span>
    </artmisads-button-special>
    <div class="activate-brand" ref="activateBrand">
      <artmisads-modal
        v-model:open="showUpdateProductModal"
        title="Bulk Management Via CSV"
        :width="1200"
        :getContainer="() => fileBarRef"
        :destroyOnClose="true"
        :maskClosable="false"
        :footer="null"
        :onCancel="onCancel"
      >
        <div>
          <exampleTable
            v-if="showExample"
            :setDataList="setDataList"
            :dataList="dataList"
            :setShowExample="setShowExample"
            :setMarketplace="setMarketplace"
            :setBillingDateTimestamp="setBillingDateTimestamp"
            :id="id"
          />
          <failSuccessList
            v-else
            :dataList="dataList"
            :showExample="showExample"
            :setDataList="setDataList"
            :setShowUpdateProductModal="setShowUpdateProductModal"
            :getList="getList"
            :setShowExample="setShowExample"
            :onCancel="onCancel"
            :marketplace="marketplace"
            :billingDateTimestamp="billingDateTimestamp"
          />
        </div>
      </artmisads-modal>
    </div>
  </div>
</template>
<script setup>
  import { onMounted, ref } from 'vue';
  import exampleTable from './exampleTable.vue';
  import failSuccessList from './failSuccessList.vue';
  import { financeApi } from "~/server/operator/finance";

  const showUpdateProductModal = ref(false);
  const showExample = ref(true);

  const billingDateTimestamp = ref('');
  const marketplace = ref('');
  const dataList = ref([]);
  const fileBarRef = ref(null);
  const props = defineProps([ 'params', 'getList', 'id']);

  const setBillingDateTimestamp = data => {
    billingDateTimestamp.value = data;
  }

  const setMarketplace = data => {
    marketplace.value = data;
  }

  const setShowUpdateProductModal = status => {
    showUpdateProductModal.value = status;
  }

  const setShowExample = status => {
    showExample.value = status;
  }
  
  const setDataList = data => {
    dataList.value = data;
  }

  const onCancel = () => {
    showUpdateProductModal.value = false;
    dataList.value = [];
    showExample.value = true;
  }

  const checkFromDashboard = () => {
    const artemisAds_dashboard_click_product_deal = localStorage.getItem('artemisAds_dashboard_click_product_deal');
    if (artemisAds_dashboard_click_product_deal === '1') {
      setShowUpdateProductModal(true);
      localStorage.setItem('artemisAds_dashboard_click_product_deal', '');
    }
  }

  onMounted(() => {
    checkFromDashboard();
  })


</script>
<style lang="less" scoped>
  .file-bar {
    display: flex;
    justify-content: right;
    padding: 14px 16px 0 16px;
    background-color: var(--color-white);
    .content-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 17px;
      border-bottom: 1px solid var(--border-4);
      > h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }
    }
    .upload-btn {
      display: flex;
      .shangchuan2 {
        width: 20px;
        height: 20px;
        fill: var(--dark-1);
        margin-right: 8px;
      }
    }
    
  }
</style>
