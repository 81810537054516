<template>
  <artmisads-modal
    v-model:open="props.showPaymentModal"
    title="Payment Confirm"
    :width="1000"
    :onCancel="() => props.setShowPaymentModal(false)"
    :destroyOnClose="true"
  >
    <a-spin :spinning="spinning">
      <div class="payment-confirm" ref="modalRef">
        <div class="item">
          <span>ArtemisAds Account:</span>
          <span>{{detailData.sellerName}} / {{detailData.sellerEmail}}</span>
        </div>
        <div class="item border">
          <span>Payment Amount:</span>
          <span>{{AMAZON_CURRENCY_MAPPER[detailData.marketplace]}}{{detailData.amount}}</span>
        </div>
        <div class="item top">
          <span>Bank Account Name: </span>
          <span>{{detailData.bandAccountName}}</span>
        </div>
        <div class="item">
          <span>Bank Transfer No.:</span>
          <span>{{detailData.bankTransferNo}}</span>
        </div>
        <div class="item border">
          <span>Seller Upload Receipt:</span>
          <a-image
            v-if="detailData.bankTransferReceiptImageUrl"
            :width="100"
            :src="detailData.bankTransferReceiptImageUrl"
          />
        </div>
        <div class="bottom-cont">
          <div class="amount">
            <span><span>*</span>Confirm paid amount</span>
            <a-input-number :min="0" v-model:value="amountValue"/>
          </div>
          <div class="receipt">
            <a-checkbox v-model:checked="checkedReceipt">Confirm to this transfer receipt</a-checkbox>
          </div>
        </div>
      </div>
    </a-spin>
    <template #footer>
      <div class="btn">
        <artmisads-button @click="() => props.setShowPaymentModal(false)">Cancel</artmisads-button>
        <a-popconfirm
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          class=""
          @confirm="financeSellerBillingsDetailsConfirmPayment"
          :getPopupContainer="() => modalRef"
        >
         <artmisads-button type="primary">Confirm</artmisads-button>
        </a-popconfirm>
      </div>
    </template>
  </artmisads-modal>
</template>

<script setup>
import { message, Modal } from "ant-design-vue";
import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
import { ref, onBeforeMount, computed, onMounted, watch } from "vue";
import { financeApi } from "~/server/operator/finance";

const props = defineProps(["showPaymentModal", "billingId", 'setShowPaymentModal']);

const modalRef = ref();
const checkedReceipt = ref(false)
const amountValue = ref('')
const detailData = ref({});
const spinning = ref(false);

const getFinanceSellerBillingsDetailsPendingReview = async () => {
  spinning.value = true;
  const params = { billingId: props.billingId };
  const res = await financeApi.getFinanceSellerBillingsDetailsPendingReview(
    params
  );
  if (res) {
    detailData.value = res;
  }
  spinning.value = false;
};

const financeSellerBillingsDetailsConfirmPayment = async () => {
  if (!amountValue.value) {
    return message.error('Please write amount');
  }
  if (!checkedReceipt.value) {
   return message.error('Please check transfer receipt');
  }
  if (Number(amountValue.value) > (detailData.value.amount - detailData.value.paidAmount)) {
    return  Modal.error({
      title: 'Notice',
      content: `Adding this record will cause the amount of paid to exceed the amount of payment(${AMAZON_CURRENCY_MAPPER[detailData.value.marketplace]}${detailData.value.amount}).Plese check again.`,
    });
  }
  spinning.value = true;
  const params = {
    billingId: props.billingId,
    confirmPaidAmount: amountValue.value
  }
  const res = await financeApi.financeSellerBillingsDetailsConfirmPayment(params);
  if (res === null) {
    message.success('Success');
    props.setShowPaymentModal(false, true);
  }
  spinning.value = false;
}

onBeforeMount(() => {
  getFinanceSellerBillingsDetailsPendingReview();
});
</script>

<style lang="less" scoped>
.payment-confirm {
  :deep(.ant-popover) {
    .ant-popover-inner {
      width: 200px;
      padding: 16px;
    }
  }
  .item {
    margin-bottom: 10px;
    &.top {
      margin-top: 20px;
    }
    &.border {
      padding-bottom: 20px;
      border-bottom: 1px solid var(--border-4);
    }
    > span:nth-child(2) {
      color: var(--dark-1);
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.3px;
      margin-left:8px;
    }
    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
      border-radius: 12px;
      margin-left:8px;
    }
  }
  .bottom-cont {
    margin-top: 20px;
    .amount {
      display: flex;
      width: 500px;
      > span {
        align-self: center;
        margin-right: 8px;
        > span {
          color: var(--red-1);
          margin-right: 2px;
          font-size: 14px;
          vertical-align: -1px;
          font-weight: 600;
        }
      }
      > input {
        width: 300px;
      }
    }
    .receipt {
      margin-top: 20px;
    }
  }
}
</style>
