<template>
  <div class="table" ref="tableRef">
    <artmisads-table
      :columns="columns"
      row-key="id"
      :data-source="props.dataList"
      :pagination="{
        total: props.listTotal,
        current: props.params.page,
        pageSize: props.params.size,
        showSizeChanger: true
      }"
      :change="onChangePage"
      tableLayout="fixed"
    >
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.name === 'showCurrency'">
          {{AMAZON_CURRENCY_MAPPER[record.marketplace]}}{{text}}
        </template>
        <template v-if="column.name === 'status'">
          {{FINANCE_PAYMENT_DETAIL_STATUS_MAP[text]?.label}}
        </template>
        <template v-if="column.dataIndex === 'paidAmount'">
          <div class="paid-amount" v-if="record.paidAmount > 0 && record.paidAmount < record.amount && (record.paidAmount / record.amount).toFixed(2) < 0.97">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-info"></use>
            </svg>
            <span>{{AMAZON_CURRENCY_MAPPER[record.marketplace]}}{{record.paidAmount}}</span>
          </div>
          <span v-else>{{AMAZON_CURRENCY_MAPPER[record.marketplace]}}{{record.paidAmount}}</span>
        </template>
        <template v-if="column.name === 'sellerName'">
          <div class="seller-name">
            <span>{{record.sellerName}}</span>
            <span>{{record.sellerEmail}}</span>
          </div>
        </template>
        <template v-if="column.name === 'brands'">
          <span class="brands-name">
            {{record.brands.replaceAll(',', ', ')}}
          </span>
        </template>
        <template v-if="column.name === 'actions'">
          <div class="actions">
            <artmisads-select-button v-if="record.status === 'paid_review'" @click="() => toDetail(record.billingId)" size="small">Review</artmisads-select-button>
            <artmisads-select-button @click="() => toInvoicePdf(record.billingId)" size="small">Invoice</artmisads-select-button>
            <a-popconfirm
                title="Are you sure to send email to seller?"
                ok-text="Yes"
                cancel-text="No"
                class=""
                @confirm="() => props.postEmail(record.billingId)"
                :getPopupContainer="() => tableRef"
              >
                <artmisads-select-button :disabled="record.isEmailSent" size="small">Email</artmisads-select-button>
              </a-popconfirm>

          </div>
        </template>
      </template>
    </artmisads-table>
  </div>
</template>
<script lang="jsx" setup>
  import { useRouter } from "vue-router";
  import moment from 'moment';
  import { ref, computed } from 'vue';
  import { message } from "ant-design-vue";
  import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
  import { FINANCE_PAYMENT_DETAIL_STATUS_MAP } from '~/contants/operator';

  const router = useRouter();
  const tableRef = ref(null);
  const props = defineProps([ 'listTotal', 'setParams', 'params', 'dataList', 'getList', 'status', 'setBillingId', 'postEmail']);
  const columns = computed(() => {
    return props.status === 'pending' ? 
    [
      {
        title: 'Payment ID',
        dataIndex: 'billingId',
        width: 140,
      },
      {
        title: 'ArtemisAds Account',
        dataIndex: 'sellerName',
        width: 120,
        name: 'sellerName',
      },
      {
        title: 'Brands Name',
        dataIndex: 'brands',
        name: 'brands',
        width: 150,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: 150,
        name: 'showCurrency',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        name: 'status',
        width: 140,
      },
    ] : [
      {
        title: 'Payment ID',
        dataIndex: 'billingId',
        width: 140,
      },
      {
        title: 'ArtemisAds Account',
        dataIndex: 'sellerName',
        width: 120,
        name: 'sellerName',
      },
      {
        title: 'Brands Name',
        dataIndex: 'brands',
        name: 'brands',
        width: 150,
      },
      {
        title: 'Invoice No.',
        dataIndex: 'invoiceNo',
        width: 150,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: 110,
        name: 'showCurrency',
      },
      {
        title: 'Paid',
        dataIndex: 'paidAmount',
        width: 110,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        name: 'status',
        width: 100,
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        name: 'actions',
        width: 240,
      },
    ]
  })

  const onChangePage = (data, filters, sorter) => {
    const { current, pageSize } = data;
    let newParams = { ...props.params };
    newParams.page = current;
    newParams.size = pageSize;
    if (sorter) {
      const { field, order } = sorter;
      newParams.sortField = field;
      newParams.sortDirection = order === 'ascend' ? 'asc' : 'desc';
    } else {
      newParams.sortField = '';
      newParams.sortDirection = '';
    }
    props.setParams(newParams);
    props.getList();
  }

  const toDetail = id => {
    props.setBillingId(id)
  }

  const toInvoicePdf = billingId => {
    const { href } = router.resolve({ path: `/operator/invoice-pdf/${billingId}` });
    window.open(href, '_blank');
  }

</script>
<style lang="less" scoped>
  .table {
    padding: 16px 0;
    :deep(.ant-tooltip-arrow) {
      display: none;
    }
    :deep(.ant-table) {
      .ant-table-thead {
        .ant-table-cell {
          line-height: 24px;
        }
      }
      .seller-name {
        display: flex;
        flex-direction: column;
        > span {
          &:first-child {
            font-weight: 600;
            margin-bottom: 2px;
          }
        }
      }
      .brands-name {
        word-break: break-word;
      }
      .paid-amount {
        color: var(--red-1);
        display: flex;
        svg {
          align-self: center;
          margin-right: 4px;
        }
      }
      .actions {
        display: flex;
        button {
          align-self: center;
          margin-right: 10px;
        }
        .confirm-btn {
          color: var(--primary-text);
          border-color: #fff;
          box-shadow: none;
        }
      }
    }
  }
</style>