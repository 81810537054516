<template>
  <div class="match-table">
    <div class="success">
      <p>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-success-tianchong"></use>
        </svg>
        <span>
          {{props.dataList.length}} updates found
        </span>
      </p>
      <artmisads-table
        :bordered="true"
        :columns="columns"
        row-key="id"
        :change="onChangeSuccessPage"
        rowClassName="table-item"
        :data-source="props.dataList"
        :scroll="{ y: 231 }"
        :pagination="{
          current: successPage,
          total: props.dataList.length,
          defaultPageSize: 10,
        }"
      >
        <template #bodyCell="{ column, record, text }">
          <template v-if="column.name === 'showCurrency'">
            {{AMAZON_CURRENCY_MAPPER[props.marketplace]}}{{text}}
          </template>
          <template v-if="column.name === 'brands'">
            <span class="brands-name">
              {{record.brands.replaceAll(',', ', ')}}
            </span>
          </template>
          <template v-if="column.name === 'status'">
            {{FINANCE_PAYMENT_DETAIL_STATUS_MAP[text].label}}
          </template>
        </template>
      </artmisads-table>
    </div>
    <div class="operation-btn">
      <artmisads-button @click="onBack">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-jiantou-zuo-cu"></use>
        </svg>
        Back
      </artmisads-button>
      <div class="update">
        <artmisads-button type="dashed" @click="onCancel">Cancel</artmisads-button>
        <artmisads-button v-if="props.dataList.length" type="primary" @click="onUpdate">Update</artmisads-button>
        <artmisads-button v-else disabled>Update</artmisads-button>
      </div>
      
    </div>
  </div>
</template>
<script lang="jsx" setup>
  import { message } from 'ant-design-vue';
  import { ref } from 'vue';
  import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
  import { FINANCE_PAYMENT_DETAIL_STATUS_MAP } from '~/contants/operator';
  import { financeApi } from "~/server/operator/finance";

  const successPage = ref(1);
  const failPage = ref(1);

  const props = defineProps([
    'dataList', 'setDataList', 'setShowExample', 'setShowUpdateProductModal', 'getList', 'onCancel', 'marketplace', 'billingDateTimestamp'
  ]);

  const onChangeSuccessPage = ({ current }) => {
    successPage.value = current;
  }
  const onUpdate = async () => {
    let billingPaymentConfirmInfos = [...props.dataList];
    billingPaymentConfirmInfos = billingPaymentConfirmInfos.map(item => {
      item.invoiceNo = item.invoiceNo;
      item.paidAmount = item.paidAmountAfter;
      item.status = item.statusAfter;
      return item;
    })
    const data = {
      billingPaymentConfirmInfos,
      marketplace: props.marketplace,
      billingDateTimestamp: props.billingDateTimestamp
    }
    let res = await financeApi.updaloadSellerBillingsDetailsConfirmBulkPayment(data);
    if (res === null) {
      message.success('Success');
      
      props.onCancel();
      const timer = setTimeout(() => {
        props.getList();
        clearTimeout(timer)
      }, 1000);
    }
  }

  const onBack = () => {
    props.setDataList([]);
    props.setShowExample(true);
  }

  const columns = ref([
    {
      title: 'ArtemisAds Account',
      dataIndex: 'artemisAdsAccount',
      width: '10%'
    },
    {
      title: 'Brands',
      dataIndex: 'brands',
      name: 'brands',
      width: '11%'
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoiceNo',
      width: '12%',
    },
    {
      title: 'DBA',
      dataIndex: 'dba',
      width: '15%'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '12%',
      name: 'showCurrency',
    },
    {
      title: 'Status',
      dataIndex: 'id',
      width: '20%',
      children: [
        {
          title: 'Before',
          dataIndex: 'statusBefore',
          name: 'status'
        },
        {
          title: 'After',
          dataIndex: 'statusAfter',
          name: 'status'
        },
      ]
    },
    {
      title: 'Paid',
      dataIndex: 'id',
      width: '20%',
      children: [
        {
          title: 'Before',
          dataIndex: 'paidAmountBefore',
          name: 'showCurrency',
        },
        {
          title: 'After',
          dataIndex: 'paidAmountAfter',
          name: 'showCurrency',
        },
      ]
    },
  ])

</script>

<style lang="less" scoped>
  .match-table {
    padding-bottom: 12px;
    .success,
    .fail {
      > p {
        display: flex;
        color: var(--dark-1);
        letter-spacing: 0.4px;
        line-height: 20px;
        margin-bottom: 12px;
        font-weight: 500;
        svg {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
        > span {
          align-self: center;
          font-size: 16px;
        }
      }
    }
    
    .success {
      padding: 8px 0 2px;
      > p {
        :deep(svg) {
          fill: var(--green-3);
        }
      }
    }
    .fail {
      margin: 0 -24px -24px;
      border-radius: 0 0 8px 8px;
      padding: 24px 24px 8px 24px;
      background-color: var(--bg-gray-1);
      border-top: 1px solid var(--border-4);
      > p {
        svg {
          fill: var(--red-1);
        }
      }
    }
    .operation-btn {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;
      > button {
        display: flex;
        svg {
          align-self: center;
          width: 18px;
          height: 18px;
          fill: var(--dark-2);
          margin-right: 8px;
        }
        :deep(span) {
          align-self: center;
        }
      }
      .update {
        > button {
          margin-left: 8px;
        }
      }
    }
    :deep(.ant-table) {
      .ant-table-cell {
        word-break: break-word;
        padding: 16px;
        line-height: 20px;
        border-color:  var(--border-4) !important;
      }
      .ant-table-thead {
        border-radius: 8px 8px 0 0;
        overflow: hidden;
        .ant-table-cell {
        border-color:  var(--border-1) !important;
        border-inline-end-color: var(--border-1) !important;
        }
      }
      .ant-table-container {
        border-color:  var(--border-4) !important;
      }
      table {
        border-top: none !important;
        border-color:  var(--border-4) !important;
      }
      .inactive-commission {
        color: var(--dark-4);
      }
      .status-text {
        text-transform: capitalize;
      }
      .product-item {
        display: flex;
        img {
          width: 60px;
          height: 60px;
          margin-right: 10px;
          object-fit: contain;
        }
        .text {
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          justify-content: center;
          .title {
            max-width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .asin {
            margin-top: 4px;
          }
        }
      }
    }
  }
  
</style>