<template>
  <div class="seller-detail">
    <div class="breadcrumbs">
      <breadcrumbs :breadcrumbsData="breadcrumbsData"/>
    </div>
    <a-spin :spinning="spinning">
      <div class="content-wrap" ref="modalRef">
        <div class="info-wrap">
          <div class="head">Payment Info</div>
          <div class="info">
            <div class="left">
              <div class="text">
                <span>Billing Date: </span>
                <span>{{moment(detailData.billingDateTimestamp).format('YYYY-MM')}}</span>
              </div>
              <div class="text">
                <span>Payment Amount: </span>
                <span>{{AMAZON_CURRENCY_MAPPER[detailData.marketplace]}}{{detailData.amount}}</span>
              </div>
              <div class="text" v-if="detailData.status !== 'pending'">
                <span>Seller Paid: </span>
                <span>{{AMAZON_CURRENCY_MAPPER[detailData.marketplace]}}{{detailData.paidAmount}}</span>
              </div>
              <div class="text">
                <span>Payment Status: </span>
                <span>{{FINANCE_SELLER_STATUS_MAP[detailData.status]?.label}}</span>
              </div>
            </div>
            <artmisads-button v-if="detailData.status === 'pending'" @click="() => setShowConfirmModal(true)" type="primary" size="middle">Confirm</artmisads-button>
          </div>
        </div>
        <div class="details">
          <div class="head">
            <span>Details</span>
            <fileBar
              :params="params"
              :getList="getData"
              :exportsNum="exportsNum"
              :id="params.id"
              v-if="detailData.status !== 'pending'"
            />
          </div>
          <div class="operation">
            <div class="left">
              <div class="item">
                <span>Seller Name</span>
                <artmisads-select
                  show-search
                  v-model:value="params.sellerId"
                  style="width: 232px"
                  :options="sellerOptions"
                  @search="searchSeller"
                  :show-arrow="false"
                  :filter-option="false"
                  @change="handleChangeSeller"
                  :not-found-content="null"
                  placeholder="All"
                  allowClear
                />
              </div>
              <div class="item" v-if="detailData.status !== 'pending'">
                <span>Status</span>
                <artmisads-select
                  style="width: 232px"
                  v-model:value="params.detailStatus"
                  :filter-option="false"
                  :not-found-content="null"
                  :options="FINANCE_PAYMENT_DETAIL_STATUS"
                  placeholder="All"
                />
              </div>
              <div class="item">
                <span>Brand</span>
                <artmisads-select
                  :show-search="!params.sellerId"
                  v-model:value="params.brandId"
                  style="width: 232px"
                  :options="brandOptions"
                  @search="searchBrand"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  placeholder="All"
                  allowClear
                />
              </div>
            </div>
            <div class="right">
              <artmisads-button
                @click="getFinanceSellerBillingsDetailsList"
                :isSquare="true"
                type="primary"
                size="middle"
                >Search</artmisads-button
              >
              <artmisads-button
                @click="() => setParams()"
                type="text"
                :isSquare="true"
                size="middle"
                >Reset</artmisads-button
              >
              <a-popconfirm
                title="Are you sure to send email to all sellers?"
                ok-text="Yes"
                cancel-text="No"
                class=""
                @confirm="postEmailToAll"
                :getPopupContainer="() => modalRef"
                :overlayStyle="{ width: '300px' }"
              >
                <artmisads-button
                  type="text"
                  :isSquare="true"
                  size="middle"
                  :disabled="detailData.isAllEmailSent"
                  >Email to All</artmisads-button>
              </a-popconfirm>
            </div>
          </div>
        </div>
        <div class="table">
          <sellerTable
            :params="params"
            :dataList="list"
            :setParams="setParams"
            :getList="getFinanceSellerBillingsDetailsList"
            :listTotal="listTotal"
            :status="detailData.status"
            :setBillingId="setBillingId"
            :postEmail="postEmail"
          />
        </div>
      </div>
    </a-spin>
    <paymentModal
      :showPaymentModal="showPaymentModal"
      :billingId="billingId"
      :setShowPaymentModal="setShowPaymentModal"
      v-if="billingId"
    />
    <artmisads-modal
      v-model:open="showConfirmModal"
      :width="500"
      title="Payment Confirm?"
      okText="Confirm"
      @ok="onChangeStatus"
    >
      <div class="active-product">
        <div class="title-ext">
          Are you sure to send payments to all sellers?
        </div>
      </div>
    </artmisads-modal>
  </div>
</template>
<script setup>
  import moment from 'moment';
  import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';
  import { financeApi } from "~/server/operator/finance";
  import { publisherAdminApi } from '~/server/index'
  import { operatorSellerApi } from "~/server/index";
  import breadcrumbs from "../../../breadcrumbs.vue";
  import sellerTable from './sellerTable';
  import fileBar from './fileBar';
  import paymentModal from './paymentModal';
  import { useRoute } from 'vue-router';
  import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
  import { FINANCE_SELLER_STATUS_MAP, FINANCE_SELLER_STATUS, FINANCE_PAYMENT_DETAIL_STATUS, FINANCE_SELLER_PAYMENT_STATUS } from '~/contants/operator';
  import { message } from 'ant-design-vue';

  const params = ref({
    id: '',
    sellerId: null,
    brandId: null,
    size: 10,
    page: 1,
    detailStatus: null
  })
  const modalRef = ref();
  const brandOptions = ref([]);
  const billingId = ref('');
  const showPaymentModal = ref(false)
  const showConfirmModal = ref(false);
  const listTotal = ref(0);
  const list = ref([]);
  const route = useRoute();
  const sellerOptions = ref([]);
  const spinning = ref(true);
  const detailData = ref({});
  const breadcrumbsData = computed(() => {
    const data = {
      title: 'Payment Details',
      links: [
        {
          name: 'Home',
          path: '/operator/users'
        },
        {
          name: 'Payment',
          path: '/operator/finance/payments'
        },
        {
          name: 'Payment Details',
          path: `/operator/finance/payments/seller-detail/${params.value.id}`
        },
      ]
    }
    return data;
  })

  const setBillingId = id => {
    billingId.value = id;
    setTimeout(() => {
      showPaymentModal.value = true;
    }, 200)
  }

  const setShowPaymentModal = (status, reload) => {
    showPaymentModal.value = status;
    if (!status) {
      billingId.value = '';
    }
    if (reload) {
      getFinanceSellerBillingsDetailsList();
      getFinanceSellerDetailInfo();
    }
  }

  const postEmailToAll = async billingId => {
    spinning.value = true;
    const params = {
      marketplace: detailData.value.marketplace,
      billingDateTimestamp: detailData.value.billingDateTimestamp
    }
    const res = await financeApi.financeSellerBillingsDetailsNotifyEmailToAll(params);
    if (res === null) {
      message.success('Success');
      getFinanceSellerDetailInfo();
      getFinanceSellerBillingsDetailsList();
    }
    spinning.value = false;
  }

  const postEmail = async billingId => {
    spinning.value = true;
    const res = await financeApi.financeSellerBillingsDetailsNotifyEmail({billingId});
    if (res === null) {
      message.success('Success');
      getFinanceSellerBillingsDetailsList();
    }
    spinning.value = false;
  }

  const getData = () => {
    getFinanceSellerDetailInfo();
    getFinanceSellerBillingsDetailsList();
  }

  const setParams = data => {
    if (data) {
      params.value = data;
    } else {
      params.value.sellerId = null;
      params.value.brandId = null;
      params.value.detailStatus = null;
      params.value.page = 1;
      getFinanceSellerBillingsDetailsList();
    }
  }

  const handleChangeSeller = (id, record) => {
    const { sellerBrands = [] } = record;
    if (sellerBrands.length) {
      brandOptions.value = sellerBrands;
      params.value.brandId = '';
    }
  }

  const searchBrand = async keyword => {
    const params = {
      keyword,
      marketplace: detailData.value.marketplace
    };
    let data = await publisherAdminApi.searchBrand(params);
    if (data && data.length) {
      data = data.map(item => {
        item.value = item.id;
        item.label = item.amazonBrandName;
        return item;
      })
      brandOptions.value = data;
    }
  }

  const setShowConfirmModal = status => {
    showConfirmModal.value = status;
  }

  const onChangeStatus = async () => {
    spinning.value = true;
    const res = await financeApi.financeSellerBillingsConfirm({id: params.value.id});
    if (res === null) {
      message.success('Success');
      showConfirmModal.value = false;
      getFinanceSellerDetailInfo();
      getFinanceSellerBillingsDetailsList();
    }
    spinning.value = false;
  }

  const searchSeller = async keyword => {
    let res, paramsData = { keyword, marketplace: detailData.value.marketplace };
    res = await publisherAdminApi.searchSeller(paramsData);
    if (res) {
      sellerOptions.value = res.map(item => {
        item.value = item.id;
        item.label = item.sellerName;
        item.sellerBrands = item.sellerBrands?.map(i => {
          i.value = i.id;
          i.label = i.amazonBrandName;
          return i;
        })
        return item;
      })
    }
  }

  const getFinanceSellerBillingsDetailsList = async () => {
    spinning.value = true;
    const res = await financeApi.getFinanceSellerBillingsDetailsList(params.value);
    const { data } = res;
    if (res && res.data) {
      listTotal.value = res.total;
      list.value = res.data;
    }
    spinning.value = false;
  }

  const getFinanceSellerDetailInfo = async () => {
    spinning.value = true;
    const res = await financeApi.getFinanceSellerDetailInfo(params.value);
    if (res) {
      detailData.value = res;
    }
    spinning.value = false;
  }

  onBeforeMount(() => {
    const { id } = route.params;
    if (id) {
      params.value.id = id;
    }
  })

  onMounted(() => {
    getFinanceSellerDetailInfo();
    getFinanceSellerBillingsDetailsList();
  })

</script>
<style lang="less" scoped>
.seller-detail {
  padding: 0 16px 16px;
  .content-wrap {
    overflow: hidden;
    border-radius: 12px;
    background-color: var(--color-white);
    box-shadow: var(--section-box-shadow-6);
    padding: 16px;
  }
  .info-wrap {
    .head {
      height: 28px;
      color: var(--dark-1);
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 28px;
      padding-bottom: 8px;
    }
    .info {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .left {
        display: flex;
        .text {
          margin-right: 30px;
          > span:last-child {
            color: var(--dark-1);
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.3px;
            margin-left:4px;
          }
        }
      }
    }
  }
  .details {
    margin-top: 20px;
    .head {
      display: flex;
      justify-content: space-between;
      > span {
        height: 28px;
        color: var(--dark-1);
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.5px;
        line-height: 28px;
        padding-bottom: 8px;
      }
    }
    .operation {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .item {
          margin-right: 20px;
          > span {
            color: var(--dark-2);
            font-weight: 500;
            font-size: 13px;
            letter-spacing: 0.3px;
            line-height: 18px;
            margin-right: 8px;
          }
        }
        
      }
      .right {
        display: flex;
        min-width: 400px;
        button {
          margin-left: 20px;
          align-self: flex-end;
        }
      }
    }
  }
  .table {
    margin-top: 20px;
  }
}


</style>